import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface NotificationState {
  notificationReadIds: number[];
}

const initialState: NotificationState = {
  notificationReadIds: []
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationClear: () => initialState,
    fetchNotificationReadIds: (state, action) => {
      state.notificationReadIds = action.payload;
    },
  },
})

export const {
  notificationClear,
  fetchNotificationReadIds,
} = NotificationSlice.actions

export default NotificationSlice.reducer
