import { WishlistsProductIdService, WishlistsPromotionIdService, WishlistsShopIdService } from '@framework/my-favorites/my-favorites.service';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import _ from 'lodash';
import { toast } from "react-toastify";

interface myFavoritesState {
  productIds: number[];
  shopIds: number[];
  promotionIds: number[];
  shopLoading: boolean;
  productLoading: boolean;
  promotionLoading: boolean;
}

const initialState: myFavoritesState = {
  productIds: [],
  shopIds: [],
  promotionIds: [],
  shopLoading: false,
  productLoading: false,
  promotionLoading: false,
}

const wishlistObjects: any = {
  product: WishlistsProductIdService,
  shop: WishlistsShopIdService,
  promotion: WishlistsPromotionIdService
}

export const initData = createAsyncThunk('favorites/init', async (type: string) => {
  const data = await wishlistObjects[type].allWishlistsIds();

  return {
    data: _.isArray(data) ? data : [],
    type: type
  }
})

export const myFavoritesSlice = createSlice({
  name: 'myFavorites',
  initialState,
  reducers: {
    clear: () => initialState,
    modifierNumberFavorites: (state, action) => {
      const { id, type, t } = action.payload;

      return produce(state, draft => {
        // @ts-ignore
        const array = draft[`${type}Ids`];

        if(array.includes(id)) {
          // @ts-ignore
          draft[`${type}Ids`] = array.filter((item: any) => item != id);
          toast.success(t("favorites.text-remove-success"));
        } else {
          // @ts-ignore
          draft[`${type}Ids`].push(id);
          toast.success(t("favorites.text-add-success"));
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initData.pending, () => {
      return produce(initialState, draft => {
        draft.shopLoading = true;
        draft.productLoading = true;
        draft.promotionLoading = true;
      });
    }),
    builder.addCase(initData.fulfilled, (state, { payload }) => {
      return produce(state, draft => {
        // @ts-ignore
        draft[`${payload?.type}Ids`] = payload.data as number[];
        // @ts-ignore
        draft[`${payload?.type}Loading`] = true;
      });
    }),
    builder.addCase(initData.rejected, (state) => {
      return produce(state, draft => {
        draft.shopLoading = false;
        draft.productLoading = false;
        draft.promotionLoading = false;
      });
    })
  },
})

export const {
  modifierNumberFavorites,
  clear
} = myFavoritesSlice.actions

export default myFavoritesSlice.reducer
