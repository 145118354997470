import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { CoreApi } from '@framework/utils/core-api';
import { API_ENDPOINTS } from '@framework/utils/endpoints';
import produce from "immer";
import { setCartSubtotal } from '@components/cart/cartSlice';

interface totalCountState {
  notifications: number;
  wishlist: number;
  cart_quantity: number;
  loading: boolean;
}

const initialState: totalCountState = {
  notifications: 0,
  wishlist: 0,
  cart_quantity: 0,
  loading: false,
}

export const initData = createAsyncThunk('totalCounts/init', async (_params, { dispatch })  => {
  const api = new CoreApi(API_ENDPOINTS.TOTAL_COUNT_HEADER);
  const { data } = await api.findAll();

  dispatch(setCartSubtotal(data.cart_subtotal));
  return data;
})

export const totalCountSlice = createSlice({
  name: 'totalCounts',
  initialState,
  reducers: {
    totalCountClear: () => initialState,
    setNotifications: (state, { payload }) => {
      state.notifications = payload.notifications
    },
    setWishlist: (state, { payload }) => {
      const number = payload.includeValue ? -1 : 1;
      state.wishlist = state.wishlist + number;
    },
    clearCartQuantity: (state) => {
      state.cart_quantity = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initData.pending, () => {
      return produce(initialState, draft => { draft.loading = true; });
    }),
    builder.addCase(initData.fulfilled, (state, { payload }) => {
      return produce(state, draft => {
        draft.notifications = payload.notifications;
        draft.wishlist = payload.wishlist;
        draft.cart_quantity = payload.cart_subtotal;
        draft.loading = true;
      });
    }),
    builder.addCase(initData.rejected, (state) => {
      return produce(state, draft => { draft.loading = false; });
    })
  },
})

export const {
  totalCountClear,
  setNotifications,
  clearCartQuantity,
  setWishlist
} = totalCountSlice.actions

export default totalCountSlice.reducer
