import { drawerAtom } from '@store/drawer-atom';
import { useAtom } from 'jotai';
import dynamic from 'next/dynamic';
import Drawer from './drawer';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { initCartData } from "@components/cart/cartSlice";
import { RootState } from 'src/root-redux/store';

const CartSidebarView = dynamic(
  () => import('@components/cart/cart-sidebars/index')
);

export default function ManagedDrawer() {
  const [{ display, view }, setDrawerState] = useAtom(drawerAtom);
  const dispatch = useDispatch();
	const { cartDataLoading }: any = useSelector((state: RootState) => state.cart);;

  useEffect(() => {
    if(view === 'cart' && !cartDataLoading) {
      dispatch(initCartData());
    }
  }, [view, cartDataLoading])

  return (
    <Drawer
      open={display}
      onClose={() => setDrawerState({ display: false, view: '' })}
      variant={
        view === 'FILTER_VIEW'
          ? 'left'
          : 'right'
      }
      viewName={view}
    >
      {view === 'cart' && <CartSidebarView />}
    </Drawer>
  );
}
