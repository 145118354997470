export const CART_KEY = 'pick-cart';
export const TOKEN = 'token';
export const AUTH_TOKEN = 'auth_token';
export const AUTH_PERMISSIONS = 'auth_permissions';
export const LIMIT = 10;
export const SUPER_ADMIN = 'super_admin';
export const CUSTOMER = 'customer';
export const CHECKOUT = 'pickbazar-checkout';
export const CZECH_PHONE_NUMBER_REGEX= /^(2[0-9]{2}|3[0-9]{2}|4[0-9]{2}|5[0-9]{2}|72[0-9]|73[0-9]|77[0-9]|60[1-8]|56[0-9]|70[2-5]|79[0-9])[0-9]{3}[0-9]{3}$/;
export const PRODUCT_SEEN = 'product-seen-new';
export const MILLISECOND_PER_DAY = 86400000;
