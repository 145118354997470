import { API_ENDPOINTS } from '@framework/utils/endpoints';
import { useQuery } from 'react-query';
import { OrderService } from './order.service';

export const fetchProductMaxQuantity = async (orderId: string | number) => {
  const { data } = await OrderService.fetchUrl(`${API_ENDPOINTS.PRODUCT_MAX_QUANTITY}/${orderId}`);

  return data;
};
export const useProductMaxQuantityQuery = (
  orderId: string | number,
  options: any = {},
  callback: any = null
) => {
  return useQuery<any, Error>([API_ENDPOINTS.PRODUCT_MAX_QUANTITY, orderId], () => fetchProductMaxQuantity(orderId), {
    ...{
      staleTime: Infinity,
      ...options,
    },
    onSuccess: (data) => {
      if (callback) {
        callback(data);
      }
    },
  });
};
