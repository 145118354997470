import type { AppProps } from 'next/app';
import AppProviders from '@framework/app/providers';
import { appWithTranslation } from 'next-i18next';
import '@assets/css/main.css';
import '@assets/css/custom-theme.css';
import { ModalProvider } from '@components/ui/modal/modal.context';
import ManagedModal from '@components/ui/modal/managed-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManagedDrawer from '@components/ui/drawer/managed-drawer';
import DefaultSeo from '@components/seo/default-seo';
import type { NextPage } from 'next';
import { RootState, store, wrapper } from '../root-redux/store';
import { Provider, useSelector } from 'react-redux';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import Maintenance from '@components/maintenance';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
  authenticate?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const { currentUser }: any = useSelector((state: RootState) => state.authenticationSettings);

  const rollbarConfig = {
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
    environment: process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV,
    payload: {
      client: {
        email: currentUser?.email
      }
    }
  };
  if(process?.env?.NEXT_PUBLIC_MAINTENANCE_MODE == 'true') {
    return (
      <Maintenance/>
    )
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <AppProviders pageProps={pageProps}>
            <ModalProvider>
              <>
                <DefaultSeo />
                {getLayout(<Component {...pageProps} />)}
                <ManagedModal />
                <ManagedDrawer />
                <ToastContainer
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  theme="light"
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </>
            </ModalProvider>
          </AppProviders>
        </Provider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}
export default wrapper.withRedux(appWithTranslation(CustomApp as any));
