import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { HYDRATE } from 'next-redux-wrapper';

interface bannnerState {
  homePage: any;
  searchPage: any;
  categoryPage: any;
  shops: any;
  companyPage: any;
  newProducts: any;
  hotProducts: any;
  akceProducts: any;
  shopDetailProduct: any;
}

const initialState: bannnerState = {
  homePage: [],
  searchPage: [],
  categoryPage: [],
  shops: [],
  companyPage: [],
  newProducts: [],
  hotProducts: [],
  akceProducts: [],
  shopDetailProduct: [],
}

export const bannerSettingsSlice = createSlice({
  name: 'bannerSettings',
  initialState,
  reducers: {
    bannerSettingsClear: () => initialState,
    setBanner: (state, action) => {
      const {key, attributes} = action.payload;

      // @ts-ignore
      state[key] = attributes;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.bannerSettings,
      };
    },
  },
})

export const {
  setBanner,
} = bannerSettingsSlice.actions

export default bannerSettingsSlice.reducer
