import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface mySettingsState {
  isMobileOnly: boolean;
  isTablet: boolean;
  isIOS: boolean;
  forwardPath: string;
  isSkipUpdateIco: boolean;
}

const initialState: mySettingsState = {
  isMobileOnly: false,
  isTablet: false,
  isIOS: false,
  forwardPath: '/',
  isSkipUpdateIco: false
}


export const mySettingsSlice = createSlice({
  name: 'i33Settings',
  initialState,
  reducers: {
    mySettingsClear: () => initialState,
    setCheckDeviceSettings: (state, action) => {
      const {isMobileOnly, isTablet, isIOS} = action.payload;

      state.isMobileOnly = isMobileOnly;
      state.isTablet = isTablet;
      state.isIOS = isIOS;
    },
    setForwardPath: (state, action) => {
      const path = action.payload;

      if(["/mobile-account", "/mobile-login", "/logout"].includes(path)) return;
      state.forwardPath = action.payload;
    },
    setIsSkipUpdateIco: (state, action) => {
      state.isSkipUpdateIco = action.payload;
    }
  },
})

export const {
  setCheckDeviceSettings,
  setForwardPath,
  setIsSkipUpdateIco
} = mySettingsSlice.actions

export default mySettingsSlice.reducer
