import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import spinner from '@assets/img/spinner.gif';
import Image from '@components/ui/image';

interface PageLoaderProps {
  text?: string;
}
const PageLoader: React.FC<PageLoaderProps> = ({ text = 'text-loading' }) => {
  const { t } = useTranslation('common');
  return (
    <div
      className={cn(
        'w-full h-screen flex flex-col items-center justify-center'
      )}
    >
      <div className="flex relative">
        <Image
          src={spinner}
          alt="spinner"
          width={50}
          height={50}
        />
      </div>
    </div>
  );
};

export default PageLoader;
