export const API_ENDPOINTS: Record<string, string> = {
  PRODUCTS: 'products',
  PRODUCT_DETAIL: 'get-product-detail',
  PRODUCT_POPUP_DETAIL: 'get-product-detail-popup',
  SETTINGS: 'api/settings',
  CUSTOMER: 'me',
  CUSTOMERS: 'users',
  COUPONS: 'coupons',
  CATEGORY: 'categories',
  PARENT_CATEGORIES: 'fetch-parent-category',
  CATEGORY_DETAIL: 'category',
  TYPE: 'types',
  UPLOAD: 'attachments',
  ORDER: 'orders',
  ORDER_STATUS: 'order_status',
  LOGIN: 'token',
  SOCIAL_LOGIN: 'social-login-token',
  SEND_OTP_CODE: 'send-otp-code',
  VERIFY_OTP_CODE: 'verify-otp-code',
  OTP_LOGIN: 'otp-login',
  UPDATE_CONTACT: 'update-contact',
  REGISTER: 'register',
  FORGET_PASSWORD: 'forget-password',
  LOGOUT: 'logout',
  CHANGE_PASSWORD: 'change-password',
  RESET_PASSWORD: 'reset-password',
  VERIFY_FORGET_PASSWORD: 'verify-forget-password-token',
  VERIFY_CHECKOUT: 'checkout/verify',
  CONTACT: 'contact',
  ADDRESS: 'address',
  UPDATE_MULTIPLE_ADDRESS: 'address/update-multiple',
  SHOPS: 'all-shop',
  FETCH_DETAIL_SHOP: 'get-detail-shop',
  PROMOTIONS: 'promotions',
  FETCH_LIST_PROMOTIONS: 'fetch-list-promotions',
  FETCH_HOT_PRODUCTS: 'fetch-hot-sale-products',
  DEFAULT_PER_PAGE: "100",
  FETCH_CATEGORIES: 'fetch-categories',
  CATEGORIES_LEVEL: '1',
  DEFAULT_PRODUCTS_PER_PAGE: "48",
  DEFAULT_SSR_PRODUCTS_PER_PAGE: "15",
  FETCH_BEST_PRODUCTS: 'fetch-best-products',
  FETCH_BEST_HOME_PRODUCTS: 'fetch-best-product-homes',
  FETCH_NEW_PRODUCTS: 'fetch-new-products',
  FETCH_BEST_SELLER_PRODUCTS: "fetch-bestseller-product",
  FETCH_NEW_SHOPS: "fetch-new-shops",
  FETCH_BEST_SHOPS: "fetch-best-shops",
  DEFAULT_SHOPS_PER_PAGE: "96",
  SHOPS_INDEX_PER_PAGE: "100",
  FETCH_SEARCH_WORD_FREQUENCY: 'api/home/words-frequency',
  FETCH_SEARCH_RESULT: 'api/home/search-es',
  FETCH_SUGGESTION: 'api/home/search-es-suggest',
  FETCH_SHOP_PRODUCTS: 'fetch-products-shop',
  FETCH_SHOP_PRODUCT_SEEN: 'fetch-best-products',
  DEFAULT_REVIEWS_PER_PAGE: "10",
  FETCH_SHOP_REVIEWS: 'get-detail-review-in-shop',
  ADD_TO_CART_PRODUCT: 'add-to-cart',
  FETCH_CART_PRODUCT: 'get-cart',
  DELETE_ALL_CART: 'delete-cart',
  DELETE_CART_SHOPS: 'delete-cart-shops',
  DELETE_CART_ITEM: 'delete-cart-item',
  UPDATE_CART_ITEM: 'update-cart-item',
  MY_FAVORITES: 'my-favorites',
  TOTAL_WISHLISTS: 'total-wishlists-product',
  FETCH_WISHLISTS_PRODUCTS: 'fetch-wishlists-products',
  FETCH_WISHLISTS_PROMOTIONS: 'fetch-wishlists-promotions',
  FETCH_WISHLISTS_SHOPS: 'fetch-wishlists-shops',
  VOTE_WISHLISTS_PRODUCT: 'vote-wishlists-product',
  VOTE_WISHLISTS_PROMOTION: 'vote-wishlists-promotion',
  VOTE_WISHLISTS_SHOP: 'vote-wishlists-shop',
  ALL_WISHLISTS_PROMOTION_ID: 'get-all-wishlists-promotionid',
  ALL_WISHLISTS_PRODUCT_ID: 'get-all-wishlists-productid',
  ALL_WISHLISTS_SHOP_ID: 'get-all-wishlists-shopid',
  DEFAULT_FAVORITES_PER_PAGE: '100',
  FETCH_RELATED_PRODUCTS_CART: 'get-suggested-products-to-cart',
  DELETE_CART_PRODUCTS_OUT_OF_STOCK: 'delete-all-deleted-cart-items',
  DELETE_CART_PRODUCTS_CHANGED_PRICE: 'delete-all-changed-price-cart-items',
  FETCH_CART_PRODUCTS_CHANGED_PRICE: 'get-changed-cart-items',
  FETCH_OUT_OF_STOCK_PRODUCTS: 'cart/deleted-cart-items',
  UPDATE_ALL_CART_ITEM_PRICE: 'update-all-cart-item-price',
  UPDATE_ALL_CART_ITEM_CHANGE_TAX: 'update-all-cart-item-change-tax',
  UPDATE_ALL_PRODUCT_MAX_QUANTITY: 'update-multiple-cart-items',
  UPDATE_CART_ITEM_PRICE: 'update-cart-item-price',
  CHECKOUT: 'checkout',
  FETCH_TOP_SHOP: 'fetch-top-shop',
  NOTIFICATIONS: 'get-notifications',
  NOTIFICATION_READ_IDS: 'get-notificationids-read',
  DELETE_NOTIFICATION: 'delete-notification',
  DELETE_ALL_NOTIFICATION: 'delete-multi-notification',
  READ_NOTIFICATION: 'read-notification',
  LASTEST_NOTIFICATION: 'get-lastest-notifications',
  READ_ALL_NOTIFICATION: 'read-all-notification',
  GET_TOTAL_NOTIFICATIONS: 'get-total-notifications',
  GET_SHOP_IN_ORDER: 'get-shop-in-order',
  REVIEW_SHOP: 'review-shop',
  PROMOTION_DETAIL: 'get-promotion-detail',
  DEFAULT_ORDERS_PER_PAGE: '100',
  CLAIMS: 'claims',
  CLAIMS_NEW: 'claims/new',
  INDUSTRIES: 'industries',
  VERIFY_EMAIL: 'verify-email',
  EXTEND_ORDERS: 'extendorders',
  ORDER_ADDITIONAL: 'cart/linkable-orders',
  CART: 'cart',
  PRODUCT_MAX_QUANTITY:"/orders/productsmaxquantity",
  TOTAL_COUNT_HEADER: "/get-total-count-header",
  SHOP_CATEGORIES: 'fetch-categories-by-shop',
  SHOP_MENU: 'all-shop-fe',
  COMPANIES: 'all-company-fe',
  FETCH_CATEGORY_SERVICES: 'fetch-category-services',
  FETCH_TOP_COMPANY: 'fetch-top-company',
  GET_DETAIL_COMPANY: 'get-detail-company',
  GET_DETAIL_SERVICE: 'get-service-detail',
  FETCH_RELATED_PRODUCT_SHOPS:'fetch-related-product-shops',
  FETCH_PRODUCT_IDS: 'fetch-product-ids',
  GET_PRODUCT_RELATED_BY_ID: "get-product-related-by-id",
  GET_PRODUCT_BY_CATEIDS: "get-product-by-cateids",
  FETCH_DELETED_PRODUCTS_IN_CART: "cart/deleted-soft-cart-items",
  DELETE_ALL_SORT_CART_ITEM: "delete-all-deleted-soft-cart-items",
  DELETE_ALL_CART_ITEM_CLOSE_SHOP: "delete-prod-shop-close-cart-items",
  DELETE_ALL_CART_ITEM_HIDE_SHOP: "delete-prod-shop-show-hide-cart-items",
  UPDATE_NOTES_SHOP: "cart/update-notes-shop",
  FETCH_ORDER_PRODUCT_DELETED: "orders/get-product-deleted-order",
  FETCH_BANNER: "/setting-banner-page",
  FETCH_GIFTS: "/giftprograms",
  GIFT_QUALIFIED: "/giftprograms/get-gift-qualified",
  ADD_GIFT_TO_CART: "/giftprograms/add-gift-to-cart",
  FETCH_ALL_CATEGORIES: 'fetch-all-categories',
  FETCH_KEYWORDS: 'fetch-list-keywords',
  EXPORT_PDF: 'api/show-invoice-fe',
  CLONE_LATEST_CART: 'cart/clone-lastest',
  FETCH_CART_ITEM_CLOSE_SHOP: 'cart/shop-close-cart-items',
  FETCH_CART_ITEM_HIDE_SHOP: 'cart/shop-show-hide-cart-items',
  FETCH_CART_ITEM_CHANGE_TAX: 'cart/get-tax-change-cart-items',
  DELETE_ALL_CART_ITEM_CHANGE_TAX: 'delete-prod-tax-change-cart-items',
  UPDATE_CART_ITEM_TAX: 'update-cart-item-price-change-tax',
  FETCH_BEST_PRODUCTS_SHOP: 'fetch-best-products-shop',
  FETCH_SEARCH_SHOP: 'fetch-filter-shop',
  FETCH_SEARCH_CATEGORY: 'fetch-category-filter',
};

