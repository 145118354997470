import { useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppSettings } from './settings';

interface AppProviderProps {
  pageProps: any;
}
const AppProviders: React.FC<AppProviderProps> = ({ pageProps, children }) => {
  const queryClientRef = useRef<any>(null);
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }
  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <AppSettings>
          {children}
        </AppSettings>
        {/* {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />} */}
      </Hydrate>
    </QueryClientProvider>
  );
};

export default AppProviders;
