import React from 'react';
import logoPlaceholder from '@assets/placeholders/linkthumb.jpg';

const siteSettings = {
  name: 'i33 | Kênh buôn bán online của người Việt',
  description: 'Với mong muốn hiện đại hóa cách thức kinh doanh trong thời đại công nghiệp 4.0, kênh thương mại điện tử i33 đã được ra đời. Chúng tôi là cầu nối giữa các nhà sản xuất, các tổng kho đổ buôn và các cửa hàng bán lẻ trên toàn thị trường cộng hòa Séc.',
  currencyCode: "CZK",
  logo: `${process.env.NEXT_PUBLIC_SHOP_URL}${logoPlaceholder?.src}`,
};

type State = typeof initialState;

const initialState = {
  siteTitle: siteSettings.name,
  siteSubtitle: siteSettings.description,
  currency: siteSettings.currencyCode,
  logo: {
    id: 1,
    thumbnail: siteSettings.logo,
    original: siteSettings.logo,
  },
  seo: {
    metaTitle: siteSettings.name,
    metaDescription: siteSettings.description,
    ogTitle: siteSettings.name,
    ogDescription: siteSettings.description,
    ogImage: {
      id: 1,
      thumbnail: siteSettings.logo,
      original: siteSettings.logo,
    },
    twitterHandle: '@handle',
    twitterCardType: 'summary_large_image',
    metaTags: '',
    canonicalUrl: '',
  },
  google: {
    isEnable: false,
    tagManagerId: '',
  },
  facebook: {
    isEnable: false,
    appId: '',
    pageId: '',
  },
  contactDetails: {
    socials: [],
    contact: '',
  },
};

export const SettingsContext = React.createContext<State | any>(initialState);

SettingsContext.displayName = 'SettingsContext';

export const SettingsProvider: React.FC<{ initialValue?: any }> = ({
  initialValue,
  ...props
}) => {
  const [state] = React.useState(initialValue ?? initialState);
  return <SettingsContext.Provider value={state} {...props} />;
};

export const useSettings = () => {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }
  return context;
};
