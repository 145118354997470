import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import produce from "immer";
import { CoreApi } from '@framework/utils/core-api';
import { API_ENDPOINTS } from '@framework/utils/endpoints';
import { fetchCartItemChangeTaxs, fetchCartItemCloseShops, fetchCartItemHideShops, fetchCartProductsChangedPrice, fetchMiniCart, fetchOutOfStockProducts } from '@framework/cart/cart.query';
import router from 'next/router';
import { fetchProductMaxQuantity } from '@framework/orders/product-max-quantity.query';

interface myCartState {
  cartItems: {};
  cartItemsLoading: boolean;
  cartDataLoading: boolean;
  cartInfo: any;
  cartPaymentInfos: any;
  cartShops: any[];
  invalidCartShops: any[];
  cartGifts: any[];
  cartItemsChangePrice: any[];
  productMaxQuantity: any[];
  cartItemsInactive: any[];
  mappingProductWithCartItems: {};
  cartItemsCloseShop: any[];
  cartItemsHideShop: any[];
  cartItemsChangeTax: any[];
}

const initialState: myCartState = {
  cartItems: {},
  cartItemsLoading: false,
  cartDataLoading: false,
  cartInfo: {
    id: null,
    orderId: null,
    count: null,
  },
  cartPaymentInfos: {
    subtotal: 0,
    tax_total: 0,
    quantity: 0,
    total: 0,
    original_subtotal: 0,
    discounted: 0
  },
  cartShops: [],
  invalidCartShops: [],
  cartGifts: [],
  cartItemsChangePrice: [],
  productMaxQuantity: [],
  cartItemsInactive: [],
  mappingProductWithCartItems: {},
  cartItemsCloseShop: [],
  cartItemsHideShop: [],
  cartItemsChangeTax: [],
}

export const initData = createAsyncThunk('cartItems/init', async ()  => {
  const api = new CoreApi(`${API_ENDPOINTS.CART}/product-in-cart-items`);
  const { data } = await api.findAll();

  return data;
});

export const initCartData = createAsyncThunk('cart/init', async ()  => {
  const data = fetchMiniCart({locale: router.locale});

  return data;
});

export const initCartChangePrice = createAsyncThunk('cartChangePrice/init', async ()  => {
  const data = fetchCartProductsChangedPrice({locale: router.locale});

  return data;
});

export const initProductMaxQuantity = createAsyncThunk('cartProductMaxQuantity/init', async (params: any)  => {
  const data = fetchProductMaxQuantity(params.orderId);

  return data;
});

export const initCartItemInActive = createAsyncThunk('cartItemOutOfStock/init', async ()  => {
  const data = fetchOutOfStockProducts({locale: router.locale});

  return data;
});

export const initShopCloseCartItem = createAsyncThunk('cartItemCloseShop/init', async ()  => {
  const data = fetchCartItemCloseShops({locale: router.locale});

  return data;
});

export const initHideShopCartItem = createAsyncThunk('cartItemHideShop/init', async ()  => {
  const data = fetchCartItemHideShops({locale: router.locale});

  return data;
});

export const initChangeTaxCartItem = createAsyncThunk('cartItemChangeTax/init', async ()  => {
  const data = fetchCartItemChangeTaxs({locale: router.locale});

  return data;
});

function getInvalidCartShops(cartShops: any) {
  return cartShops?.filter((item: any) => {
    return !item.is_gte_min_order;
  }) || [];
}

function filterIgnoreCartShops(cartShops: any, cartShopIds: any) {
  return cartShops.filter((item: any) => {
    return !cartShopIds.includes(item.id);
  })
}

function getPaymentInfo(data: any) {
  return _.pick(data, ["subtotal", "tax_total", "quantity", "total", "original_subtotal", "discounted"]);
}

function getCartItem(cartItems: any, newValue: any, cartItemId: any) {
  if(!cartItems) return [newValue];

  if(newValue) {
    const indexToReplace = cartItems.findIndex((item: any) => item.id === cartItemId);
    if (indexToReplace !== -1) {
      cartItems.splice(indexToReplace, 1, newValue);
    } else {
      cartItems.push(newValue);
    }

    return cartItems;
  }

  return cartItems.filter((i: any) => i.id != cartItemId);
}

function filterCartItemFromCartShop(cartItems: any, cartShopIds: any) {
  const filteredData = Object.entries(cartItems).filter(([key, _value]) => cartShopIds.includes(Number(key)));

  return Object.fromEntries(filteredData);
}

function getCartShops(cartShops: any, newValue: any, ignoreCheckSubtotal = false) {
  if(!cartShops) return [newValue];

  if(newValue?.subtotal != 0 || ignoreCheckSubtotal) {
    const indexToReplace = cartShops.findIndex((item: any) => item.id === newValue.id);
    if (indexToReplace !== -1) {
      cartShops.splice(indexToReplace, 1, {...cartShops[indexToReplace], ...newValue});
    } else {
      cartShops.push(newValue);
    }

    return cartShops;
  }

  return cartShops.filter((i: any) => i.id != newValue.id);
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    cartClear: () => initialState,
    cartClearWithoutLoading: (state) => {
      return produce(initialState, (draft: myCartState) => {
        draft.cartItemsLoading = state.cartItemsLoading;
        draft.cartDataLoading = state.cartDataLoading;
      });
    },
    clearCartGifts: (state, _action) => {
      state.cartGifts = [];
    },
    clearCartItemsChangePrice: (state, _action) => {
      state.cartItemsChangePrice = [];
    },
    clearProductMaxQuantity: (state, _action) => {
      state.productMaxQuantity = [];
    },
    clearCartItemsInactive: (state, _action) => {
      state.cartItemsInactive = [];
    },
    clearCartItemsCloseShop: (state, _action) => {
      state.cartItemsCloseShop = [];
    },
    clearCartItemsHideShop: (state, _action) => {
      state.cartItemsHideShop = [];
    },
    clearCartItemsChangeTax: (state, _action) => {
      state.cartItemsChangeTax = [];
    },
    setItemQuantity: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const {cartItemId, data: {cart, cart_shop, cart_item}, cart_gifts}: any = action.payload;
        const cartItems = {...draft.cartItems};
        const cartShops = getCartShops(draft.cartShops, cart_shop);
        const cartShopIds = cartShops.map((i: any) => i.id);
        const mappingProductWithCartItems: any = {...draft.mappingProductWithCartItems}
        if(cart_item) mappingProductWithCartItems[cart_item?.product?.id] = cart_item?.id;

        draft.cartPaymentInfos = getPaymentInfo(cart);
        draft.cartInfo = {...state.cartInfo, ...{ id: cart.id, orderId: cart.orderId }}
        // @ts-ignore
        draft.cartItems = {...cartItems, [cart_shop.id]: getCartItem(cartItems[cart_shop.id], cart_item, cartItemId)};
        draft.cartShops = cartShops;
        draft.mappingProductWithCartItems = mappingProductWithCartItems;
        draft.invalidCartShops = getInvalidCartShops(cartShops);
        draft.cartItemsChangePrice = state.cartItemsChangePrice.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsInactive = state.cartItemsInactive.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsCloseShop = state.cartItemsCloseShop.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsHideShop = state.cartItemsHideShop.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsChangeTax = state.cartItemsChangeTax.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        if(cart_gifts) draft.cartGifts = cart_gifts || [];
      });
    },
    setCartSubtotal: (state, action) => {
      return produce(state, (draft: myCartState) => {
        draft.cartPaymentInfos = {
          subtotal: action.payload
        }
      });
    },
    setCartInfo: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const { cart_shops, orderId, id, cart_gifts }: any = action.payload;
        const cartShopIds = cart_shops.map((i: any) => i.id);

        draft.cartPaymentInfos = getPaymentInfo(action.payload);
        draft.cartShops = cart_shops;
        draft.cartInfo = {...state.cartInfo, ...{ id, orderId }};
        draft.cartItems = filterCartItemFromCartShop(state.cartItems, cartShopIds)
        draft.invalidCartShops = getInvalidCartShops(cart_shops);
        draft.cartItemsChangePrice = state.cartItemsChangePrice.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsInactive = state.cartItemsInactive.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsCloseShop = state.cartItemsCloseShop.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsHideShop = state.cartItemsHideShop.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsChangeTax = state.cartItemsChangeTax.filter((i: any) => cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartGifts = cart_gifts || [];
      });
    },
    deleteSoftDeleteCartItem: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const { data, data: { cart_shops, order_id: orderId, id, cart_gifts}, cartItemDeleted }: any = action.payload;
        const cartItemDeletedIds = cartItemDeleted.map((i: any) => i.id);
        const newCartItems: any = {...state.cartItems}

        for (const [key, value] of Object.entries(newCartItems)) {
          const filteredValues = (value as any).filter((obj: any) => !cartItemDeletedIds.includes(Number(obj.id)))
          newCartItems[key] = filteredValues;
        }

        draft.cartPaymentInfos = getPaymentInfo(data);
        draft.cartShops = cart_shops;
        draft.cartInfo = {...state.cartInfo, ...{ id, orderId }};
        draft.cartItems = newCartItems
        draft.invalidCartShops = getInvalidCartShops(cart_shops);
        draft.cartItemsChangePrice = state.cartItemsChangePrice.filter((i: any) => !cartItemDeletedIds.includes(Number(i.id)));
        draft.cartItemsInactive = state.cartItemsInactive.filter((i: any) => !cartItemDeletedIds.includes(Number(i.id)));
        draft.cartItemsCloseShop = state.cartItemsCloseShop.filter((i: any) => !cartItemDeletedIds.includes(Number(i.id)));
        draft.cartItemsHideShop = state.cartItemsHideShop.filter((i: any) => !cartItemDeletedIds.includes(Number(i.id)));
        draft.cartItemsChangeTax = state.cartItemsChangeTax.filter((i: any) => !cartItemDeletedIds.includes(Number(i.id)));
        draft.cartGifts = cart_gifts || [];
      });
    },
    setCartGifts: (state, action) => {
      return produce(state, (draft: myCartState) => {
        draft.cartGifts = action.payload;
      });
    },
    deleteCartShop: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const { cartShopIds, data } = action.payload;
        let cartItems: any = {...draft.cartItems};

        cartShopIds.map((id: any) => { delete cartItems[id]; });

        draft.cartPaymentInfos = getPaymentInfo(data);
        draft.cartItems = cartItems;
        draft.cartShops = filterIgnoreCartShops([...state.cartShops], cartShopIds);
        draft.invalidCartShops = filterIgnoreCartShops([...state.invalidCartShops], cartShopIds);
        draft.cartItemsChangePrice = state.cartItemsChangePrice.filter((i: any) => !cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsInactive = state.cartItemsInactive.filter((i: any) => !cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsCloseShop = state.cartItemsCloseShop.filter((i: any) => !cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsHideShop = state.cartItemsHideShop.filter((i: any) => !cartShopIds.includes(Number(i.cart_shop_id)));
        draft.cartItemsChangeTax = state.cartItemsChangeTax.filter((i: any) => !cartShopIds.includes(Number(i.cart_shop_id)));
      });
    },
    setCart: (state, action) => {
      const cartDatas = action.payload;

      return produce(state, (draft: any) => {
        draft.cartPaymentInfos = getPaymentInfo(cartDatas.cart);
        draft.cartShops = cartDatas.cart_shop || [];
        draft.invalidCartShops = getInvalidCartShops(cartDatas.cart_shop);
        draft.cartInfo = { id: cartDatas.id, orderId: cartDatas.order_id };
        draft.cartGifts = cartDatas?.cart_gifts;
        draft.cartItems = cartDatas.cart_item?.reduce((obj: any, item: any) => {
          const cartItems = obj[item.cart_shop_id] || [];
          obj[item.cart_shop_id] = [...cartItems, item];

          return obj;
        }, {});
        draft.cartItemsLoading = true;
        draft.cartDataLoading = true;
      });
    },
    setCartShopNotes: (state, action) => {
      return produce(state, (draft: myCartState) => {
        draft.cartShops = action.payload;
      });
    },
    setToggleCartShop: (state, action) => {
      return produce(state, (draft: any) => {
        draft.cartShops = getCartShops(draft.cartShops, {...action.payload, toggle: !action.payload.toggle}, true);
      });
    },
    deleteCartItemsChangePrice: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const id = action.payload;

        draft.cartItemsChangePrice = state.cartItemsChangePrice.filter((i: any) => i.id != id);
      });
    },
    deleteCartItemsInactive: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const id = action.payload;

        draft.cartItemsInactive = state.cartItemsInactive.filter((i: any) => i.id != id);
      });
    },
    deleteCartItemsCloseShop: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const id = action.payload;

        draft.cartItemsCloseShop = state.cartItemsCloseShop.filter((i: any) => i.id != id);
      });
    },
    deleteCartItemsHideShop: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const id = action.payload;

        draft.cartItemsHideShop = state.cartItemsHideShop.filter((i: any) => i.id != id);
      });
    },
    deleteCartItemsChangeTax: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const id = action.payload;

        draft.cartItemsChangeTax = state.cartItemsChangeTax.filter((i: any) => i.id != id);
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initData.fulfilled, (state, { payload }) => {
      return produce(state, (draft: {
        mappingProductWithCartItems: any; cartItems: any; cartItemsLoading: boolean;
      }) => {
        const cartItemReduce = payload?.reduce((obj: any, item: any) => {
          const cartItems = obj.cartItems[item.cart_shop_id] || [];
          obj.cartItems[item.cart_shop_id] = [...cartItems, item].sort((a: any, b: any) => {
            if (a.is_out_of_stock === b.is_out_of_stock) {
              return a.id - b.id;
            } else {
              return a.is_out_of_stock ? -1 : 1;
            }
          });
          ;
          obj.mappingProducts[item.product.id] = item.id

          return obj;
        }, {cartItems: {}, mappingProducts: {}});

        draft.cartItems = cartItemReduce.cartItems
        draft.mappingProductWithCartItems = cartItemReduce.mappingProducts
        draft.cartItemsLoading = true;
      });
    }),
    builder.addCase(initCartData.fulfilled, (state, { payload }) => {
      const cartDatas: any = payload;

      return produce(state, (draft: any) => {
        draft.cartPaymentInfos = getPaymentInfo(cartDatas);
        draft.cartShops = cartDatas.cart_shops?.map((cartShop: any) => ({...cartShop, toggle: false})) || [];
        draft.invalidCartShops = getInvalidCartShops(cartDatas.cart_shops);
        draft.cartInfo = {...state.cartInfo, ...{ id: cartDatas.id, orderId: cartDatas.order_id }};
        draft.cartGifts = cartDatas?.cart_gifts;
        draft.cartDataLoading = true;
      });
    }),
    builder.addCase(initCartChangePrice.fulfilled, (state, { payload }) => {
      return produce(state, (draft: { cartItemsChangePrice: any}) => {
        draft.cartItemsChangePrice = payload;
      });
    }),
    builder.addCase(initProductMaxQuantity.fulfilled, (state, { payload }) => {
      return produce(state, (draft: { productMaxQuantity: any}) => {
        draft.productMaxQuantity = payload;
      });
    }),
    builder.addCase(initCartItemInActive.fulfilled, (state, { payload }) => {
      return produce(state, (draft: { cartItemsInactive: any}) => {
        draft.cartItemsInactive = payload;
      });
    }),
    builder.addCase(initShopCloseCartItem.fulfilled, (state, { payload }) => {
      return produce(state, (draft: { cartItemsCloseShop: any}) => {
        draft.cartItemsCloseShop = payload;
      });
    }),
    builder.addCase(initHideShopCartItem.fulfilled, (state, { payload }) => {
      return produce(state, (draft: { cartItemsHideShop: any}) => {
        draft.cartItemsHideShop = payload;
      });
    }),
    builder.addCase(initChangeTaxCartItem.fulfilled, (state, { payload }) => {
      return produce(state, (draft: { cartItemsChangeTax: any}) => {
        draft.cartItemsChangeTax = payload;
      });
    })
  },
})

export const {
  cartClear,
  clearProductMaxQuantity,
  cartClearWithoutLoading,
  clearCartItemsChangePrice,
  clearCartItemsInactive,
  clearCartItemsCloseShop,
  clearCartItemsHideShop,
  clearCartItemsChangeTax,
  clearCartGifts,
  setItemQuantity,
  setCartSubtotal,
  setCartInfo,
  setCartGifts,
  setCartShopNotes,
  setToggleCartShop,
  deleteCartShop,
  deleteCartItemsChangePrice,
  deleteSoftDeleteCartItem,
  deleteCartItemsInactive,
  deleteCartItemsCloseShop,
  deleteCartItemsHideShop,
  deleteCartItemsChangeTax,
  setCart,
} = cartSlice.actions

export default cartSlice.reducer
