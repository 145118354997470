import { createSlice } from '@reduxjs/toolkit';
import produce from "immer";

interface myCartState {
  activeViewAllProducts: any;
}

const initialState: myCartState = {
  activeViewAllProducts: {},
}

export const cartToggleSlice = createSlice({
  name: 'cartToggle',
  initialState,
  reducers: {
    cartClear: () => initialState,
    setActiveViewAllProducts: (state, action) => {
      return produce(state, (draft: myCartState) => {
        const item = action.payload;
        const newValue = { ...state.activeViewAllProducts };
        if(state.activeViewAllProducts[item.id] != undefined) {
          newValue[item.id] = !state.activeViewAllProducts[item.id];
        } else {
          newValue[item.id] = false;
        }
        draft.activeViewAllProducts = newValue;
      });
    },
  },
})

export const {
  cartClear,
  setActiveViewAllProducts,
} = cartToggleSlice.actions

export default cartToggleSlice.reducer
