import { createSlice } from '@reduxjs/toolkit';

type ConfirmInformation = {
  address_detail: {value: string}[];
};

type FinishOrderStep = {
  note: string;
}

interface CartOrderState {
  changePriceLoading: any;
  currentStep: string;
  mobileReCheck: string;
  confirmInformation: ConfirmInformation;
  finishOrderStep: FinishOrderStep;
}

const initialState: CartOrderState = {
  currentStep: 'init-order-step',
  mobileReCheck: '',
  confirmInformation: {
    address_detail: [{ value: "" }],
  },
  finishOrderStep: {
    note: "",
  },
  changePriceLoading: false,
}

export const steps = [
  'init-order-step',
  'confirm-information-order-step',
  'finish-order-step'
];

export const cartOrderSlice = createSlice({
  name: 'cartorder',
  initialState,
  reducers: {
    clear: () => initialState,
    backStep: (state) => {
      let stepIndex = steps.indexOf(state.currentStep);
      if(stepIndex == 0) return;
      state.currentStep = steps[stepIndex - 1];
    },
    nextStep: (state) => {
      let currentStep = state.currentStep
      let stepIndex = steps.indexOf(currentStep);
      if(stepIndex >= steps.length - 1) return;
      state.currentStep = steps[stepIndex + 1];
    },
    setInformationData: (state, action) => {
      const { data, key } = action.payload;
      if(key) {
        // @ts-ignore
        state[key] = Object.assign(state[key], data);
      } else {
        state = Object.assign(state, data);
      }
    },
    setReCheck: (state, action) => {
      state.mobileReCheck = action.payload;
    },
  },
})

export const {
  clear,
  backStep,
  nextStep,
  setInformationData,
  setReCheck,
} = cartOrderSlice.actions

export default cartOrderSlice.reducer
