import { combineReducers } from 'redux'
import registerReducer from '@components/auth/registers/registerSlice';
import cartOrderReducer from '@components/cart/cartOrderSlice';
import myFavoritesReducer from '@components/my-favorites/myFavoritesSlice';
import notificationReducer from '@components/notifications/notificationSlice';
import totalCountSliceReducer from '@components/layouts/totalCountSlice';
import i33SettingsReducer from '@framework/app/settingsSlice';
import authenticationSettingsReducer from '@framework/app/authenticationSlice';
import bannerSettingsSlice from '@components/banners/bannerSlice';
import cartReducer from '@components/cart/cartSlice';
import cartToggleReducer from '@components/cart/cartToggleSlice';

export default combineReducers({
  register: registerReducer,
  myFavorites: myFavoritesReducer,
  cartOrder: cartOrderReducer,
  notifications: notificationReducer,
  totalCounts: totalCountSliceReducer,
  i33Settings: i33SettingsReducer,
  authenticationSettings: authenticationSettingsReducer,
  bannerSettings: bannerSettingsSlice,
  cart: cartReducer,
  cartToggle: cartToggleReducer,
})
