import {
  MiniCartService,
  CartProductsChangedPrice,
  SuggestedProductsService,
  AddToCartInputType,
  UpdateItemInputType,
  UpdateCartItemInputType,
  DeleteCartShopType,
  CartOutOfStockProduct,
  DeletedProductsInCartService,
  UpdateNotesShopInputType,
  GiftProgramService,
  CartItemCloseShopService,
  CartItemHideShopService,
  CartItemChangeTaxService,
} from '@framework/cart/cart.service';
import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '@framework/utils/endpoints';
import { toast } from "react-toastify";
import { useTranslation } from 'next-i18next';
import { mapPaginatorData } from '@framework/utils/data-mappers';

type PaginatedMiniCart = {
  cartItems: [];
};

type MiniCartQueryOptionsType = {
  locale: string | undefined;
}

type CartProductsChangedPriceQueryOptionsType = {
  locale: string | undefined;
}

type SuggestedProductsQueryOptionsType = {
  locale: string | undefined;
}

const fetchMiniCart = async (params: MiniCartQueryOptionsType): Promise<PaginatedMiniCart> => {
  const {locale} = params;
  const response = await MiniCartService.all(locale);
  return response;
};


const useMiniCartQuery = (
  params: MiniCartQueryOptionsType,
  options: any = {},
) => {
  return useQuery<any, Error>([API_ENDPOINTS.FETCH_CART_PRODUCT, params],
    () => fetchMiniCart(params), {
      ...options
    },
  );
};

const fetchCartProductsChangedPrice = async (params: CartProductsChangedPriceQueryOptionsType) => {
  const {locale} = params;
  const response = await CartProductsChangedPrice.all(locale);
  return response;
};


const useCartProductsChangedPriceQuery = (
  params: CartProductsChangedPriceQueryOptionsType,
  options: any = {},
  callback: any = null,
) => {
  return useQuery<any, Error>([API_ENDPOINTS.FETCH_CART_PRODUCTS_CHANGED_PRICE, params],
    () => fetchCartProductsChangedPrice(params), {
      ...options,
      onSuccess: (data) => {
        if (callback) {
          callback(data);
        }
      },
    },
  );
};

const fetchSuggestedProductsCart = async (params: SuggestedProductsQueryOptionsType) => {
  const {locale} = params;
  const response = await SuggestedProductsService.all(locale);
  return response;
};

const useSuggestedProductsCartQuery = (
  params: SuggestedProductsQueryOptionsType,
  options: any = {},
) => {
  return useQuery<any, Error>([API_ENDPOINTS.FETCH_RELATED_PRODUCTS_CART, params],
    () => fetchSuggestedProductsCart(params), {
      ...options,
      staleTime: Infinity
    },
  );
};

export const fetchTotalCartItems = async () => {
  const data = await MiniCartService.all();

  return data.quantity || 0;
};

export const useTotalCartItemsQuery = (
  options: any = {},
) => {
  return useQuery<any, Error>(API_ENDPOINTS.FETCH_CART_PRODUCT,
    () => fetchTotalCartItems(), {
      ...options,
    },
  );
};

export { useMiniCartQuery, fetchMiniCart, useSuggestedProductsCartQuery, fetchSuggestedProductsCart, fetchCartProductsChangedPrice, useCartProductsChangedPriceQuery };


export const useAddToCartMutation = () => {
  return useMutation((input: AddToCartInputType) =>
    MiniCartService.addToCartProduct(input)
  );
};

export const useUpdateItemProductCart = () => {
  return useMutation((input: UpdateItemInputType) =>
    MiniCartService.updateItemProductCart(input)
  );
};

export const useUpdateCartItemPrice = () => {
  return useMutation((input: UpdateCartItemInputType) =>
    MiniCartService.updateCartItemPrice(input)
  );
};

export const useUpdateCartItemTax = () => {
  return useMutation((input: UpdateCartItemInputType) =>
    MiniCartService.updateCartItemTax(input)
  );
};

export const useUpdateAllCartItemPrice = () => {
  const { t } = useTranslation('common');

  return useMutation(() =>
    MiniCartService.updateAllCartItemPrice(),
    {
      onSuccess: () => {
        toast.success(t('text-cart-page-update-cart-item-price'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useUpdateAllCartItemChangeTax = () => {
  const { t } = useTranslation('common');

  return useMutation(() =>
    MiniCartService.updateAllCartItemChangeTax(),
    {
      onSuccess: () => {
        toast.success(t('text-cart-page-update-cart-item-change-tax'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useUpdateAllProductMaxQuantity = () => {
  const { t } = useTranslation('common');

  return useMutation((params: any) =>
    MiniCartService.updateUpdateAllProductMaxQuantity(params),
    {
      onSuccess: () => {
        toast.success(t('text-cart-page-update-all-product-max-quanity'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteAllCartItemCloseShop = () => {
  const { t } = useTranslation('common');

  return useMutation(() =>
    MiniCartService.deleteAllCartItemCloseShop(),
    {
      onSuccess: () => {
        toast.success(t('text-page-cart-delete-all-cart-item-close-shop'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteAllCartItemHideShop = () => {
  const { t } = useTranslation('common');

  return useMutation(() =>
    MiniCartService.deleteAllCartItemHideShop(),
    {
      onSuccess: () => {
        toast.success(t('text-page-cart-delete-all-cart-item-hide-shop'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteAllCartItemChangeTax = () => {
  const { t } = useTranslation('common');

  return useMutation(() =>
    MiniCartService.deleteAllCartItemChangeTax(),
    {
      onSuccess: () => {
        toast.success(t('text-page-cart-delete-all-cart-item-change-tax'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteAllCart = () => {
  const { t } = useTranslation('common');

  return useMutation(
    () => MiniCartService.deleteAllCart(),
    {
      onSuccess: () => {
        toast.success(t('text-minicart-deleted-done'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteCartProductsOutOfStock = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  return useMutation(
    () => MiniCartService.deleteCartProductsOutOfStock(),
    {
      onSuccess: () => {
        toast.success(t('text-page-cart-delete-all-cart-item-inactive'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteCartProductsChangedPrice = () => {
  const { t } = useTranslation('common');

  return useMutation(
    () => MiniCartService.deleteCartProductsChangedPrice(),
    {
      onSuccess: () => {
        toast.success(t('text-page-cart-delete-all-product-changed-price'));
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteCartShops = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (input: DeleteCartShopType) => MiniCartService.deleteCartShops(input),
    {
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useDeleteCartItem = () => {
  return useMutation(
    (input: { id: number }) => MiniCartService.deleteCartItem(input),
    {
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export const useCheckout = () => {
  const { t } = useTranslation('common');

  return useMutation(
    (input: any) => MiniCartService.checkout(input)
  );
};

export const useCloneLatestCart = () => {
  return useMutation(
    (_input: any) => MiniCartService.postUrl(API_ENDPOINTS.CLONE_LATEST_CART, {})
  );
};

export const fetchOutOfStockProducts = async (params: CartProductsChangedPriceQueryOptionsType) => {
  const { locale } = params;
  const response = await CartOutOfStockProduct.all(locale);
  return response;
};

export const fetchCartItemCloseShops = async (params: CartProductsChangedPriceQueryOptionsType) => {
  const { locale } = params;
  const response = await CartItemCloseShopService.all(locale);
  return response;
};

export const fetchCartItemHideShops = async (params: CartProductsChangedPriceQueryOptionsType) => {
  const { locale } = params;
  const response = await CartItemHideShopService.all(locale);
  return response;
};

export const fetchCartItemChangeTaxs = async (params: CartProductsChangedPriceQueryOptionsType) => {
  const { locale } = params;
  const response = await CartItemChangeTaxService.all(locale);
  return response;
};

export const useOutOfStockProductsQuery = (
  params: CartProductsChangedPriceQueryOptionsType,
  options: any = {},
  callback: any = null,
) => {
  return useQuery<any, Error>([API_ENDPOINTS.FETCH_OUT_OF_STOCK_PRODUCTS, params],
    () => fetchOutOfStockProducts(params), {
      ...options,
      onSuccess: (data) => {
        if (callback) {
          callback(data);
        }
      },
    },
  );
};

export const fetchProducts = async ({ pageParam }: any) => {
  let fetchedData: any = {};

  if(pageParam) {
    fetchedData = await MiniCartService.fetchUrl(pageParam)
      .then((res) => res.data);;
  } else {
    fetchedData = await MiniCartService.fetchCartItems();
  }

  const { data, ...rest } = fetchedData;

  return { data, paginatorInfo: mapPaginatorData({ ...rest }) };
}

export const useInfiniteCartProducts = () => {
  return useInfiniteQuery('cart/cart-items', fetchProducts, {
    staleTime: Infinity,
    keepPreviousData: true,
    getNextPageParam: ({ paginatorInfo }) => paginatorInfo.nextPageUrl,
  })
}

export const fetchDeletedSoftCartItems = async () => {
  const response = await DeletedProductsInCartService.all();

  return response;
};

export const useDeletedSoftCartItems = (
  options: any = {},
) => {
  return useQuery<any, Error>(API_ENDPOINTS.FETCH_DELETED_PRODUCTS_IN_CART,
    () => fetchDeletedSoftCartItems(), {
      ...options,
    },
  );
};

export const useDeleteAllSoftCartItem = () => {
  return useMutation((id: any) => DeletedProductsInCartService.deleteAllSoftCartItem());
};

export const useUpdateNotesShop = () => {
  return useMutation((input: UpdateNotesShopInputType) =>
    MiniCartService.updateNotesShop(input)
  );
};

export const fetchOrderProductDeleted = async (orderId: any) => {
  const response = await DeletedProductsInCartService.fetchOrderProductDeleted(orderId);

  return response;
};

export const useFetchOrderProductDeleted = (
  orderId: any,
  options: any = {},
) => {
  return useQuery<any, Error>([API_ENDPOINTS.FETCH_ORDER_PRODUCT_DELETED, orderId],
    () => fetchOrderProductDeleted(orderId), {
      ...options,
      staleTime: true
    },
  );
};

export const fetchGifts = async () => {
  const { data, ...rest } = await GiftProgramService.fetchGifts();

  return { gifts: data, paginatorInfo: mapPaginatorData({ ...rest }) };
};

export const useFetchGifts = (
  options: any = {},
) => {
  return useQuery<any, Error>(API_ENDPOINTS.FETCH_GIFTS,
    () => fetchGifts(), {
      ...options,
      staleTime: Infinity
    },
  );
};

export const fetchGiftQualified = async () => {
  const data = await GiftProgramService.fetchGiftQualified();

  return { gifts: data };
};

export const useFetchGiftQualified = (
  options: any = {},
) => {
  return useQuery<any, Error>(API_ENDPOINTS.GIFT_QUALIFIED,
    () => fetchGiftQualified(), {
      ...options,
    },
  );
};

export const useAddGiftToCart = () => {
  return useMutation((input: any) =>
    GiftProgramService.addGiftToCart(input)
  );
};
