export const ROUTES = {
  HOME: '/',
  CART:'/cart',
  CHECKOUT: '/checkout',
  PROFILE: '/user-information/profile',
  CHANGE_PASSWORD: '/user-information/change-password',
  FAVORITES_PRODUCTS: '/my-favorites/products',
  FAVORITES_SHOPS: '/my-favorites/shops',
  FAVORITES_PROMOTIONS: '/my-favorites/promotions',
  FAVORITES_MOBILE: '/my-favorites/mobile',
  ORDERS: '/orders',
  HELP: '/help',
  LOGOUT: '/logout',
  OFFERS: '/offers',
  ORDER_RECEIVED: '/order-received',
  PRODUCT: '/products',
  ORDER: '/order',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  CONTACT: '/contact',
  SHIP_FEE: '/fee-ship',
  SHOPS: '/shops',
  PROMOTIONS: '/promotions',
  BEST_SHOPS: '/best-shops',
  NEW_SHOPS: '/new-shops',
  NOTIFICATIONS: '/notifications',
  ORDERS_HISTORY: '/orders-history',
  CLAIMS: '/claims',
  CATEGORIES: '/categories',
  HOT_AKCES: '/hot-akces',
  BEST_PRODUCTS: '/best-products',
  NEW_PRODUCTS: '/new-products',
  EXTEND_ORDERS: '/extend-orders',
  ACCOUNT: '/mobile-account',
  MOBILE_LOGIN: '/mobile-login',
  MOBILE_CHANGE_PASSWORD: '/mobile-change-password',
  SEARCH: '/search',
  MOBILE_ACCOUNT_INFO: '/mobile-account/info',
  MOBILE_ACCOUNT: '/mobile-account',
  COMPANIES: '/companies',
  SERVICES: '/services',
  HOT_PRODUCTS: '/hot-products',
  AKCE_PRODUCTS: '/akce-products',
  NOT_FOUND: '/404'
};
