import { CloseIcon } from '@components/icons/close-icon';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { isMobileOnly } from "react-device-detect";
import cn from "classnames";

export default function Modal({ open, onClose, view, children }: any) {
  const cancelButtonRef = useRef(null);
  const { t } = useTranslation('common');

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className={cn("fixed inset-0 z-[1000] dynamic-modal-flag", view != "SEARCH_POPUP" && "overflow-y-auto")}
        initialFocus={cancelButtonRef}
        static
        open={open}
        onClose={onClose}
      >
        <div className="min-h-full text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50 w-full h-full" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className={cn("inline-block h-screen", view != "SEARCH_POPUP" && "align-middle")}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={cn("min-w-content text-start transition-all relative modal-height",
              view == "SEARCH_POPUP" ? "w-full h-full" : "max-w-full align-middle",
              isMobileOnly && view == "ORDER_PRODUCT_DELETED_POPUP" ? "" : "inline-block"
            )}>
              {open && <button
                onClick={onClose}
                aria-label="Close panel"
                ref={cancelButtonRef}
                className={cn("inline-block outline-none focus:outline-none absolute end-5 top-5 z-[60]",
                  (isMobileOnly || ["ORDER_PRODUCT_DELETED_POPUP", "PRODUCT_DETAILS"].includes(view)) && "hidden"
                )}
              >
                <span className="sr-only">{t('text-close')}</span>
                <CloseIcon />
              </button>}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
