import Image from 'next/image';

const ImageLoader = ({ src }: any) => {
  return src;
};

export const CustomImage: React.FC<any> = ({ ...rest }) => {
  return (
    <Image
      {...rest}
      loader={ImageLoader}
    />
  );
};

export default CustomImage;
