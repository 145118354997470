import { CoreApi } from '@framework/utils/core-api';
import { API_ENDPOINTS } from '@framework/utils/endpoints';

class MyFavorites extends CoreApi {
  constructor(_base_path: string) {
    super(_base_path);
  }

  all() {
    return this.http.post(API_ENDPOINTS.TOTAL_WISHLISTS)
    .then((res) => res.data)
    .catch(() => 0);
  }

  allWishlistsIds() {
    return this.http.get(this._base_path)
    .then((res) => res.data)
    .catch(() => []);;
  }
}

export const MyFavoritesService = new MyFavorites('myFavorites');
export const WishlistsProductIdService = new MyFavorites(API_ENDPOINTS.ALL_WISHLISTS_PRODUCT_ID);
export const WishlistsShopIdService = new MyFavorites(API_ENDPOINTS.ALL_WISHLISTS_SHOP_ID);
export const WishlistsPromotionIdService = new MyFavorites(API_ENDPOINTS.ALL_WISHLISTS_PROMOTION_ID);
